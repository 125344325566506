/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames'

import Layout from '@/layouts/Default'

import styles from './styles.module.scss'

export default function LocationsView(props) {
    return (
        <Layout {...props}>
            <div className="container">
                <div className={classNames('row', styles.hero)}>
                    <div className="col col--xs-12">
                        <h1 className={classNames('t-heading1', styles.heading)}>
                            Saatva Viewing Rooms
                        </h1>
                        <div className={styles.subheading}>
                            Experience Saatva comfort in real life in an inviting,
                            pressure-free environment before enjoying your{' '}
                            <a href="https://www.saatva.com/trial">365-night home trial</a>.
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={classNames('row u-marginBottom--3xl', styles.locationsRow)}>
                    {props.locations.map((location) => {
                        return (
                            <div
                                key={location.address.postalCode}
                                className="col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl"
                            >
                                <a href={`/locations/${location.slug}`}>
                                    {location.c_hubPhoto && (
                                        <img src={location.c_hubPhoto.url} className={styles.image} alt="location hub photo" />
                                    )}
                                    <div className={styles.locationTitle}>
                                        {`${location.c_customName}`}
                                    </div>
                                    <div className={styles.locationTitle}>
                                        {`Saatva ${location.c_neighborhood}`}
                                    </div>
                                </a>
                                <div>{`${location.address.line1}, ${location.address.city}, ${location.address.region} ${location.address.postalCode}`}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}
